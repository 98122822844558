.main-content ul {
    list-style-type: none;
    padding-left: 0;
}

.main-content li {
    margin-bottom: 5px;
}

.main-content a {
    color: var(--text-color);
}