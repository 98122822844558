.job-title {
    display: inline-block;
    background: var(--primary);
    padding: 2px 4px;
    margin-top: 7px;
    font-size: 1.2em;
    text-transform: uppercase;
    color: var(--text-color-bg);
}

.job-title__value {
    margin: 0;
}

.bio__first {
    position: relative;
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
}

.bio__name {
    margin: 0;
}

@media (max-width: 580px) {
    .bio__name {
        max-width: 155px;
        font-size: 1.5em;
    }
}

@media (max-width: 360px) {
    .bio__first span {
        position: absolute;
        right: 25px;
        top: 0;
    }

    .bio__birthday {
        max-width: 190px;
    }
}