.logo-wrapper {
    position: relative;
    display: flex;
    align-items: flex-end;
    z-index: 2;
}

.logo-wrapper::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 70%;
    height: calc(100% - (170px / 1.3));
    background: var(--primary);
    transform: translateX(-50%);
    z-index: -1;
}

.logo {
    width: 170px;
    height: 170px;
}

@media (max-width: 500px) {
    .logo-wrapper {
        align-items: center;
    }
}