.photo__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 330px;
    text-align: center;
    font-size: 11px;
    z-index: 2;
    margin-top: 50px;
    color: var(--text-color-bg);
    background: linear-gradient(to right, transparent 15%, var(--primary) 15%, var(--primary) 85%, transparent 85%, transparent 100%);
}

.photo__wrapper::after {
    content: '';
    position: absolute;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 9px solid var(--text-color-bg);
    bottom: 155px;
    left: 40px;
    transform: translateX(-50%);
}

.photo__wrapper::before {
    content: '';
    position: absolute;
    top: 30px;
    left: 40px;
    width: 2px;
    height: calc(100% - (143px + (25px * 2)));
    background: var(--text-color-bg);
    transform: translateX(-50%);
}

.photo {
    margin: 0 auto;
    max-width: 143px;
    border-radius: 25px;
    overflow: hidden;
}

@media (max-width: 500px) {
    .photo__wrapper {
        min-height: 250px;
        margin-top: -20px;
        flex-shrink: 0;
    }
}

@media (max-width: 366px) {
    .photo__wrapper {
        min-height: auto;
        margin-top: 0;
        flex-shrink: 0;
        width: 145px;
    }
    .photo__wrapper::after, .photo__wrapper::before {
        display: none;
    }

    .photo {
        width: 120px;
    }
}