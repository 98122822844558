.header {
    display: grid;
    grid-template-columns: 170px auto;
    grid-gap: 25px;
}

.header__content {
    padding-top: 75px;
}

@media (max-width: 500px) {
    .header {
        grid-template-columns: 110px auto;
    }
}