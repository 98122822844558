*, *::after, *::before {
    box-sizing: border-box;
}

:root {
    --begie: #E5E5E5;
    --black: black;
    --yellow: #FEC706;
    --white: white;
    --dark-grey: #232323;

    --bg-light: var(--begie);
    --color-text-light: var(--black);
    --primary-light: var(--yellow);

    --bg-dark: var(--dark-grey);
    --color-text-dark: var(--white);
    --primary-dark: var(--yellow);

    --bg-color: var(--bg-light);
    --text-color: var(--color-text-light);
    --primary: var(--primary-light);
    --text-color-bg: var(--black);
}

body {
    margin: 0;
    font-family: 'Anonymous Pro', monospace;
    font-size: 14px;
}

.App {
    --bg-color: var(--bg-light);
    --text-color: var(--color-text-light);
    --primary: var(--primary-light);

    background: var(--bg-color);
    color: var(--text-color);
    min-height: 100vh;
}

.App[data-theme="dark"] {
    --bg-color: var(--bg-dark);
    --text-color: var(--color-text-dark);
    --primary: var(--primary-dark)
}

.wrapper {
    max-width: 580px;
    margin: 0 auto;
}

.layout-line {
    display: grid;
    grid-template-columns: 170px auto;
    grid-gap: 25px;
}

.app__wrapper {
    align-items: flex-start;
}

.app__bl--separate {
    position: relative;
}

.app__bl--separate::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 2px;
    background: var(--text-color);
    margin-left: calc(-25px / 2);
}

.reset-list {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
}

.title {
    margin-top: 0;
}

@media (max-width: 500px) {
    .layout-line {
        grid-template-columns: 1fr;
    }

    .app__bl--lf {
        display: flex;
        padding: 0 25px;
    }

    .app__bl--separate {
        padding: 0 25px;
        margin-top: -55px;
    }
}

@media (max-width: 366px) {
    .app__bl--separate {
        margin-top: 0;
    }
}